<template>
  <div class="w-full">
    <div class="flex flex-col sm:flex-row items-center pt-5 border-b border-gray-200">
      <h2 class="font-medium text-base mr-auto">退款订单</h2>
      <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
        <button class="button mr-2 mb-2 flex items-center justify-center bg-theme-1 text-white" @click="refreshData()">
          <RefreshCwIcon class="w-4 h-4 mr-2" />
          刷新
        </button>
      </div>
    </div>

    <!-- 数据 -->
    <div class="intro-y box p-5 w-full">
      <!-- 头部 查询表单 -->
      <div class="flex flex-col w-full sm:flex-row items-center mb-5 border-b border-gray-200">
        <el-form ref="formData" :model="formData" label-width="100px" size="small" style="width: 100%;">
          <div class="card-container-div">
            <el-form-item label="退款订单号">
              <el-input></el-input>
            </el-form-item>
            <el-form-item label="支付订单号">
              <el-input></el-input>
            </el-form-item>
            <el-form-item label="商户退款单号">
              <el-input></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-input></el-input>
            </el-form-item>
            <el-form-item label="日期">
              <el-input></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">搜索</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
            <el-form-item></el-form-item>
            <el-form-item></el-form-item>
          </div>
        </el-form>
      </div>

      <!-- 中部 数据 -->
      <el-table ref="tableData" :data="[{}]" stripe @selection-change="handleSelectionChange">
        <el-table-column label="支付金额" align="left"></el-table-column>
        <el-table-column label="退款金额" align="left"></el-table-column>
        <el-table-column label="退款订单号" align="left"></el-table-column>
        <el-table-column label="支付订单号" align="left"></el-table-column>
        <el-table-column label="商户退款单号" align="left"></el-table-column>
        <el-table-column label="状态" align="left"></el-table-column>
        <el-table-column label="创建日期" align="left"></el-table-column>
        <el-table-column label="操作" align="left">
          <span style="color:#327CFC; cursor: pointer;" @click="openDetailDialog()">查看详情</span>
        </el-table-column>
      </el-table>

      <!-- 底部 分页 -->
      <div class="border-t border-gray-200 pt-5 flex mt-5">
        <el-pagination
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20, 30, 50, 100, 200]"
            :page-size="limit"
            layout="total, prev, pager, next, sizes, jumper"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </div>

    <!-- 详情弹窗 -->
    <DetailDialog :show-dialog="isDetailDialogVisible"
                  :select-item="detailDialogSelectItem"
                  @handle-close="isDetailDialogVisible = false"
                  @handle-success="_fetchData()"
    ></DetailDialog>

  </div>
</template>

<script>
import DetailDialog from './components/detail-dialog'

/**
 *
 */
export default {
  components: { DetailDialog },
  data() {
    return {
      // 详情弹窗
      isDetailDialogVisible: false,
      detailDialogSelectItem: {},
      // 表单数据
      formData: {},
      // 分页数据
      totalCount: 0,
      currentPage: 1,
      limit: 20,
      // 表格数据
      tableData: []
    }
  },
  created() {
    // 初始化
    this._fetchData()
  },
  methods: {
    // 打开查看详情
    openDetailDialog() {
      //
      this.isDetailDialogVisible = true
    },
    // 刷新
    refreshData() {
      this._fetchData(true)
    },
    // 表格选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // limit 改变
    handleSizeChange(val) {
      this.limit = val
      this._fetchData()
    },
    // 当前页面改变
    handleCurrentChange(val) {
      this.currentPage = val
      this._fetchData()
    },
    // 重置表单
    resetForm() {
      this.$refs.formData.resetFields()
      this._fetchData()
    },
    // 提交表单
    submitForm() {
      this._fetchData()
    },
    // 基础查询
    _fetchData(toast = false) {
      if (toast) this.$message.success('刷新成功～')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-container-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-form-item {
      width: 320px;
      .el-input {
        width: 96%;
      }
      .el-select {
        width: 96%;
      }
      .el-input__inner {
        width: 96%;
      }
    }
  }
</style>